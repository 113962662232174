import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import CopyMailToClipboardComponent from "components/common/copy-to-clipboard";
import { Container } from "components/style";
import * as SC from "./style";

// РЕДИЗАЙН: NEW (Потом убрать приставку New)
const PaymentChoicesNew = ({
  corporateOfferLink = "/pdf/corporate-offer-february-2025.pdf",
}) => {
  const image = useStaticQuery(graphql`
    query granichFigmaGiftImageNew {
      giftExample: file(
        relativePath: { eq: "granich-figma/gift-example.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      present: file(relativePath: { eq: "gift-box.png" }) {
        childImageSharp {
          fluid(maxWidth: 350, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const giftExample = image.giftExample.childImageSharp.fluid;
  const present = image.present.childImageSharp.fluid;
  return (
    <SC.StyledSection>
      <Container>
        <SC.StyledSectionTitleGaramond>
          Возможности <span>оплаты</span>
        </SC.StyledSectionTitleGaramond>
        <SC.Wrapper>
          <SC.FirstPaymentChoice>
            <SC.PaymentChoiceInnerWrapper>
              <SC.PaymentChoiceTitle>Картой, СБП, долями</SC.PaymentChoiceTitle>
              <SC.FirstBlockPaymentChoiceText>
                Вы можете оплатить напрямую с карты (РФ или зарубежной), через
                СБП, в рассрочку или долями. Все способы смотрите в форме
                оплаты↑
              </SC.FirstBlockPaymentChoiceText>
            </SC.PaymentChoiceInnerWrapper>

            <SC.PaymentChoiceIllustrationsWrapper>
              <SC.StyledSpbIcon />
              <SC.StyledMirIcon />
              <SC.StyledDolyamiIcon />
              <SC.StyledMastercardIcon />
              <SC.StyledVisaIcon />
            </SC.PaymentChoiceIllustrationsWrapper>
          </SC.FirstPaymentChoice>
          <SC.SecondPaymentChoice>
            <SC.PaymentChoiceInnerWrapper>
              <SC.PaymentChoiceTitle>
                Корпоративное обучение
              </SC.PaymentChoiceTitle>
              <SC.CorporateDocsWrapper>
                <SC.StyledDiadockIcon />
                <SC.PdfLink href={corporateOfferLink} target="_blank">
                  <SC.StyledPdfIcon />
                  <span>Коммерческое предложение</span>
                </SC.PdfLink>
              </SC.CorporateDocsWrapper>
              <SC.SecondBlockPaymentChoiceText>
                Работаем через ЭДО Диадок. Для старта напишите нам на почту
                <CopyMailToClipboardComponent>
                  <SC.Mail>hello@granich.design</SC.Mail>
                </CopyMailToClipboardComponent>
              </SC.SecondBlockPaymentChoiceText>
            </SC.PaymentChoiceInnerWrapper>

            <SC.PaymentChoiceIllustrationsWrapper>
              <SC.StyledCorporateIcon />
            </SC.PaymentChoiceIllustrationsWrapper>
          </SC.SecondPaymentChoice>
          <SC.PaymentChoice>
            <SC.PaymentChoiceInnerWrapper>
              <SC.PaymentChoiceTitle>Криптой</SC.PaymentChoiceTitle>
              <SC.ThirdBlockPaymentChoiceText>
                Возможна оплата через TON и ETH. Запросите, мы пришлём адрес
                кошелька.
              </SC.ThirdBlockPaymentChoiceText>
            </SC.PaymentChoiceInnerWrapper>

            <SC.PaymentChoiceIllustrationsWrapper>
              {" "}
              <SC.StyledTonIcon />
              <SC.StyledEthIcon />
            </SC.PaymentChoiceIllustrationsWrapper>
          </SC.PaymentChoice>
          <SC.PaymentChoice>
            <SC.PaymentChoiceInnerWrapper>
              <SC.PaymentChoiceTitle>Из-за рубежа</SC.PaymentChoiceTitle>
              <SC.FourthBlockPaymentChoiceText>
                Также у нас есть прием платежей из-за рубежа. Чтобы начать
                оплату перейдите к заполнению обычной формы ↑
              </SC.FourthBlockPaymentChoiceText>
            </SC.PaymentChoiceInnerWrapper>

            <SC.PaymentChoiceIllustrationsWrapper>
              <SC.StyledGlobeIcon />
            </SC.PaymentChoiceIllustrationsWrapper>
          </SC.PaymentChoice>
        </SC.Wrapper>
        <SC.GiftPaymentChoice>
          <SC.PaymentChoiceInnerWrapper>
            <SC.PaymentChoiceTitle>А можно и в подарок</SC.PaymentChoiceTitle>
            <SC.FifthBlockPaymentChoiceText>
              Вы можете купить учебный продукт в подарок. Для этого напишите нам
              на
              <CopyMailToClipboardComponent>
                <SC.Mail>hello@granich.design</SC.Mail>
              </CopyMailToClipboardComponent>
              , что вы хотите сделать подарок. Мы поможем оформить заказ и
              пришлём поздравительный сертификат.
            </SC.FifthBlockPaymentChoiceText>
          </SC.PaymentChoiceInnerWrapper>

          <SC.GiftExampleImage fluid={giftExample} />
          <SC.PresentImage fluid={present} />
        </SC.GiftPaymentChoice>
      </Container>
    </SC.StyledSection>
  );
};

export default PaymentChoicesNew;
