import { useState } from "react";

const useFormatPhoneNumber = () => {
  const [formattedNum, setFormattedNum] = useState("");
  const formatPhoneNumber = (value) => {
    // Если значение начинается с +7, 8 или 7
    if (value.startsWith("+7") || value.startsWith("8") || value.startsWith("7")) {
      // Удаляем все символы, кроме цифр
      let digits = value.replace(/\D/g, "");
      
      // Если номер начинается с 8 или 7, заменяем первую цифру на 7
      if (digits[0] === "8" || digits[0] === "7") {
        digits = "7" + digits.slice(1);
      }
      
      // Форматируем в виде: +7 xxx xxx-xx-xx
      let formatted = "+7";
      if (digits.length > 1) {
        formatted += ' ' + digits.substring(1, 4);
      }
      if (digits.length >= 5) {
        formatted += ' ' + digits.substring(4, 7);
      }
      if (digits.length >= 8) {
        formatted += '-' + digits.substring(7, 9);
      }
      if (digits.length >= 10) {
        formatted += '-' + digits.substring(9, 11);
      }
      
      return formatted;
    }
    // Если условие не выполняется — возвращаем исходное значение
    return value;
  };

  const handleChangeWithFormattedPhone = (event, handleChange) => {
    const formattedValue = formatPhoneNumber(event.target.value);
    handleChange(event, formattedValue);
    setFormattedNum(formattedValue);
  }

  return { formattedNum, handleChangeWithFormattedPhone };
};

export default useFormatPhoneNumber;
